<template>
    <div class="step_upload_box marginT_10">
        <div>{{title}}</div>
        <slot></slot>
        <a-form-model-item
          :style= width100percent
          :prop="prop"
        >
          <a-upload
            list-type="picture"
            name="file"
            :file-list="fileList"
            :customRequest="awsUpload"
            @preview="handleFileRead"
            :remove="handleFileRemove"
          >
            <a-button
            :disabled="disabled"
            > <a-icon type="upload" /> Upload </a-button>
          </a-upload>
        </a-form-model-item>
        <div class="step_notice_text marginT_10">
          The file's fomat supports JPG,PNG,JPEG,PDF or any video types
          and the size is not exceed 20MB
        </div>
      </div>
</template>

<script>
import AWS from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex'
import { getAwsPic } from '@/api/common'

export default {

  methods: {
    beforeUpload (file) {
      // this.$emit('beforeUpload', file)
    },
    fileAdd (obj) {
      if (this.fileList) {
        const res = this.fileList
        res.push(obj)
        this.$emit('handleFileChange', res, this.prop)
      } else {
        const res = []
        res.push(obj)
        this.$emit('handleFileChange', res, this.prop)
      }
    },
    handleFileRemove (info) {
      if (this.disabled) {
        return
      }
      const __fileList = JSON.parse(JSON.stringify(this.fileList))
      const i = __fileList.findIndex(({ key }) => (key === info.key))
      if (i > -1) {
        __fileList.splice(i, 1)
        if (__fileList.length > 0) {
          this.$emit('handleFileChange', __fileList, this.prop)
        } else {
          this.$emit('handleFileChange', null, this.prop)
        }
      }
    },
    handleFileRead (info) {
      if (info?.key) {
        const query = {
          key: info.key
        }
        getAwsPic(query).then(res => {
          if (res?.url) {
            window.open(res.url, '_blank')
          }
        })
      }
    },
    awsUpload (info) {
      if (info && info.file && info.file.size) {
        if (info.file.size > 20 * 1024 * 1024) {
          this.$message.error('This file is too big!')
          return
        }
      }
      var file = info.file
      var Bucket = this.AwsToken.bucket
      var bucketRegion = this.AwsToken.region
      var IdentityPoolId = this.AwsToken.identity_pool_id
      // 获取尾缀
      const tail = file.name.split('.').pop()
      // 生成唯一id 识别
      var Key = uuidv4() + '.' + tail
      var result = {
        name: file.name,
        uid: file.uid
      }
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId
        })
      })

      var upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket,
          Key,
          Body: file,
          ContentType: file.type
        }
      })
      var promise = upload.promise()

      promise.then(res => {
        result.id = null
        result.key = Key
        this.fileAdd(result)
        this.$emit('FileValidate', this.prop)
      }
      ).catch(() => {
        // console.log(err)
      })
    }
  },
  data () {
    return {
      width100percent: {
        width: '100%'
      }
    }
  },
  props: {
    title: {
      type: String
    },
    prop: {
      type: String
    },
    fileList: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('login', ['AwsToken'])
  }
}
</script>

<style scoped>
.marginT_10 {
  margin-top: 10px;
}
.step_notice_text {
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
</style>
