<template>
  <div class="step_container">
    <h1 class="step_title">CONTROL STRUCTURE 组织架构</h1>
    <div class="step_question_container">
      <div class="step_question_title">
        Directors/25%+ Shareholders Details 董事/持股超过25%股东信息
      </div>
      <a-tabs v-model="activeKey" type="editable-card" @edit="onEdit" :hideAdd="getPanesLength >= 5 || disabled">
        <a-tab-pane
          v-for="item in director_panes"
          :key="item.key"
          :tab="item.title"
          :closable="item.closable"
        >
          <a-form-model
          :rules='rules'
          :model='item'
          :ref="'ShareHolder' + item.key"
          layout="horizontal" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item
            prop="entity_director_postion" label="">
              <a-radio-group
                class="modify_antd_radio"
                name="entity_director_postion"
                :disabled='disabled'
                :value="item.entity_director_postion"
                @change="
                  ({ target: { value } }) => {
                    handleChange(item.key, 'entity_director_postion', value);
                  }
                "
              >
                <a-radio value="director">Director 董事 </a-radio>
                <a-radio value="more25shareholder">>25% Shareholder </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <div class="mutiple_form_layout">
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="Title 称谓"
                prop="entity_director_title"
              >
                <a-select
                  :disabled='disabled'
                  @change="
                    value => {
                      handleChange(item.key, 'entity_director_title', value);
                    }
                  "
                  :value="item.entity_director_title"
                >
                  <a-select-option value="Mr"> Mr </a-select-option>
                  <a-select-option value="Miss"> Miss </a-select-option>
                  <a-select-option value="Ms"> Ms </a-select-option>
                  <a-select-option value="Other"> Other </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="First Name 名"
                prop="entity_director_first_name"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_first_name"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_first_name', value);
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="Last Name 姓"
                prop="entity_director_last_name"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_last_name"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_last_name', value);
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <a-form-model-item prop="entity_director_gender" label="Gender 性别">
              <a-radio-group
                :disabled='disabled'
                class="modify_antd_radio"
                name="entity_director_gender"
                :value="item.entity_director_gender"
                @change="
                  ({ target: { value } }) => {
                    handleChange(item.key, 'entity_director_gender', value);
                  }
                "
              >
                <a-radio value="male"> Male 男 </a-radio>
                <a-radio value="female"> Female 女 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <div class="mutiple_form_layout">
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="Date of Birth 生日"
                prop="entity_director_dob"
              >
                <a-date-picker
                  :disabled='disabled'
                  style="width: 100%"
                  @change="
                    e => {
                      handleChange(item.key, 'entity_director_dob', e.format());
                    }
                  "
                  :inputReadOnly="true"
                  :value="item.entity_director_dob"
                  placeholder="Date of Birth"
                >
                </a-date-picker>
              </a-form-model-item>
              <BaseCountrySelection
                :style="GetScreenStyle.width30percent"
                label="Nationality 国籍(及地区)"
                prop="entity_director_nationality"
                :disabled="disabled"
                :selectedVal='item.entity_director_nationality'
                @handleChange="
                    (prop,value) => {
                      handleChange(item.key, 'entity_director_nationality', value);
                    }
                  "
                valType='code'
              >
              </BaseCountrySelection>
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="Place of Birth 出生地"
                prop="entity_director_place_of_birth"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_place_of_birth"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_place_of_birth', value);
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <div class="mutiple_form_layout">
              <BaseCountrySelection
                :style="GetScreenStyle.width45percent"
                label="Country of Tax Residence 税务国家"
                prop="entity_director_tax_country"
                :disabled="disabled"
                :selectedVal='item.entity_director_tax_country'
                @handleChange="
                    (prop,value) => {
                      handleChange(item.key, prop, value);
                    }
                  "
                valType='code'
              >
              </BaseCountrySelection>
              <a-form-model-item
                :style="GetScreenStyle.width45percent"
                label="Tax Number 税号"
                prop="entity_director_tax_num"
              >
                <a-input
                  :disabled='disabled'
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_tax_num', value);
                    }
                  "
                  :value="item.entity_director_tax_num"
                />
              </a-form-model-item>
            </div>
            <div class="mutiple_form_layout">
              <a-form-model-item
                :style="GetScreenStyle.width60percent"
                prop="entity_director_address"
                label="Residential Address居住地址"
              >
                <BaseAddress
                  prop="entity_director_address"
                  :disabled="disabled"
                  :value="item.entity_director_address"
                  @handleChange='(prop,value) => {
                    handleChange(item.key, prop, value)
                  }'
                />
              </a-form-model-item>
              <BaseCountrySelection
              :style="GetScreenStyle.width30percent"
              label="Country 国家(及地区)"
              prop="entity_director_country"
              :disabled="disabled"
              :selectedVal='item.entity_director_country'
              @handleChange="
                    (prop,value) => {
                      handleChange(item.key, prop, value);
                    }
                  "
              valType='code'
            >
            </BaseCountrySelection>
            </div>
            <div class="mutiple_form_layout">
              <a-form-model-item
                prop="entity_director_suburb"
                :style="GetScreenStyle.width30percent"
                label="Suburb 地区"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_suburb"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_suburb', value);
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item
                prop="entity_director_city"
                :style="GetScreenStyle.width30percent"
                label="City 城市"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_city"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_city', value);
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                prop="entity_director_postcode"
                label="Postcode 邮政编码"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_postcode"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_postcode', value);
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <div class="mutiple_form_layout">
              <BaseCountrySelection
                :style="GetScreenStyle.width30percent"
                label="Area Code 区号"
                prop="entity_director_dialcode"
                :disabled="disabled"
                :selectedVal='item.entity_director_dialcode'
                @handleChange="
                    (prop,value) => {
                      handleChange(item.key, prop, value);
                    }
                  "
                valType='dial_code'
              >
              </BaseCountrySelection>
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                prop="entity_director_phonenum"
                label="Mobile 移动电话"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_phonenum"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_phonenum', value);
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item
                prop="entity_director_email"
                :style="GetScreenStyle.width30percent"
                label="E-mail 电子邮箱"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_email"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_email', value);
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <div class="mutiple_form_layout">
              <a-form-model-item
                :style="GetScreenStyle.width45percent"
                label="Identification type(证件类型)"
                prop="entity_director_identification_type"
              >
                <a-select
                  :disabled='disabled'
                  :value="item.entity_director_identification_type"
                  @change="
                    value => {
                      handleChange(item.key, 'entity_director_identification_type', value);
                    }
                  "
                >
                  <a-select-option value="Passport">
                    Passport(Recommend)
                  </a-select-option>
                  <a-select-option value="birth_certificate">
                    Birth Certificate
                  </a-select-option>
                  <a-select-option value="business_certificate">
                    Business Certificate
                  </a-select-option>
                  <a-select-option value="chinese_id_card">
                    Chinese ID Card
                  </a-select-option>
                  <a-select-option value="citizenship_certificate">
                    Citizenship Certificate
                  </a-select-option>
                  <a-select-option value="eightteen_plus_card">
                    NZ 18+ Card
                  </a-select-option>
                  <a-select-option value="nz_firearm_license">
                    NZ Firearm License
                  </a-select-option>
                  <a-select-option value="nz_au_drivers_licence">
                    NZ/AU Drivers Licence
                  </a-select-option>
                  <a-select-option value="other"> Other </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                :style="GetScreenStyle.width45percent"
                label="ID number 证件号码"
                prop="entity_director_id_num"
              >
                <a-input
                  :disabled='disabled'
                  :value="item.entity_director_id_num"
                  @change="
                    ({ target: { value } }) => {
                      handleChange(item.key, 'entity_director_id_num', value);
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <div class="mutiple_form_layout">
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="Issue Date 签发日期"
                prop="entity_director_issue_date"
              >
                <a-date-picker
                  :disabled='disabled'
                  :inputReadOnly="true"
                  style="width: 100%"
                  @change="
                    value => {
                      handleChange(item.key, 'entity_director_issue_date', value.format());
                    }
                  "
                  :value="item.entity_director_issue_date"
                >
                </a-date-picker>
              </a-form-model-item>
              <a-form-model-item
                :style="GetScreenStyle.width30percent"
                label="Expiry Date失效日期"
                prop="entity_director_expire_date"
              >
                <a-date-picker
                  :disabled='disabled'
                  :inputReadOnly="true"
                  style="width: 100%"
                  @change="
                    value => {
                      handleChange(item.key, 'entity_director_expire_date', value.format());
                    }
                  "
                  :value="item.entity_director_expire_date"
                >
                </a-date-picker>
              </a-form-model-item>
              <BaseCountrySelection
                :style="GetScreenStyle.width30percent"
                label="Issue Country(签发国家)"
                prop="entity_director_issue_country"
                :disabled="disabled"
                :selectedVal='item.entity_director_issue_country'
                @handleChange="
                    (prop,value) => {
                      handleChange(item.key, prop, value);
                    }
                  "
                valType='code'
              >
              </BaseCountrySelection>
            </div>
            <div>
              <BaseId
                :disabled='disabled'
                @handleChange="(variable, value)=> {
                  handleChange(item.key,variable,value)
                }"
                @FileValidate="(filename)=> {validateField(filename, item.key)}"
                :proveChannel="item.entity_director_id_proveChannel"
                proveChannel_str="entity_director_id_proveChannel"
                :proveMethod="item.entity_director_id_proveMethod"
                proveMethod_str="entity_director_id_proveMethod"
                :nz_au_passport_num="item.entity_director_nz_au_passport_num"
                nz_au_passport_num_str="entity_director_nz_au_passport_num"
                :nz_au_passport_country="item.entity_director_nz_au_passport_country"
                nz_au_passport_country_str="entity_director_nz_au_passport_country"
                :nz_au_passport_file="item.entity_director_nz_au_passport_file"
                nz_au_passport_file_str="entity_director_nz_au_passport_file"
                :nz_au_driver_num="item.entity_director_nz_au_driver_num"
                nz_au_driver_num_str="entity_director_nz_au_driver_num"
                :nz_au_driver_country="item.entity_director_nz_au_driver_country"
                nz_au_driver_country_str="entity_director_nz_au_driver_country"
                :nz_au_driver_front_file="item.entity_director_nz_au_driver_front_file"
                nz_au_driver_front_file_str="entity_director_nz_au_driver_front_file"
                :nz_au_driver_back_file="item.entity_director_nz_au_driver_back_file"
                nz_au_driver_back_file_str="entity_director_nz_au_driver_back_file"
                :cn_passport_num="item.entity_director_cn_passport_num"
                cn_passport_num_str="entity_director_cn_passport_num"
                :cn_passport_country="item.entity_director_cn_passport_country"
                cn_passport_country_str="entity_director_cn_passport_country"
                :cn_passport_file="item.entity_director_cn_passport_file"
                cn_passport_file_str="entity_director_cn_passport_file"
                :cn_passport_visa_file="item.entity_director_cn_passport_visa_file"
                cn_passport_visa_file_str="entity_director_cn_passport_visa_file"
                :cn_identify_num="item.entity_director_cn_identify_num"
                cn_identify_num_str="entity_director_cn_identify_num"
                :cn_identify_country="item.entity_director_cn_identify_country"
                cn_identify_country_str="entity_director_cn_identify_country"
                :cn_identify_front_file="item.entity_director_cn_identify_front_file"
                cn_identify_front_file_str="entity_director_cn_identify_front_file"
                :cn_identify_back_file="item.entity_director_cn_identify_back_file"
                cn_identify_back_file_str="entity_director_cn_identify_back_file"
                :cn_bank_num="item.entity_director_cn_bank_num"
                cn_bank_num_str="entity_director_cn_bank_num"
                :cn_bank_country="item.entity_director_cn_bank_country"
                cn_bank_country_str="entity_director_cn_bank_country"
                :cn_bank_file="item.entity_director_cn_bank_file"
                cn_bank_file_str="entity_director_cn_bank_file"
                :cn_passport_option_num="item.entity_director_cn_passport_option_num"
                cn_passport_option_num_str="entity_director_cn_passport_option_num"
                :cn_passport_option_country="item.entity_director_cn_passport_option_country"
                cn_passport_option_country_str="entity_director_cn_passport_option_country"
                :cn_passport_option_file="item.entity_director_cn_passport_option_file"
                cn_passport_option_file_str="entity_director_cn_passport_option_file"
                :cn_passport_visa_option_file="item.entity_director_cn_passport_visa_option_file"
                cn_passport_visa_option_file_str="entity_director_cn_passport_visa_option_file"
                :other_passport_option_num="item.entity_director_other_passport_option_num"
                other_passport_option_num_str="entity_director_other_passport_option_num"
                :other_passport_option_country="
                  item.entity_director_other_passport_option_country
                "
                other_passport_option_country_str="entity_director_other_passport_option_country"
                :other_passport_option_file="item.entity_director_other_passport_option_file"
                other_passport_option_file_str="entity_director_other_passport_option_file"
                :other_passport_visa_option_file="
                  item.entity_director_other_passport_visa_option_file
                "
                other_passport_visa_option_file_str="entity_director_other_passport_visa_option_file"
              ></BaseId>
              <BaseAddressVertify
                :disabled='disabled'
                radio_var="entity_director_addressProveType"
                :address_prove_type="item.entity_director_addressProveType"
                address_prove_file_list_str="entity_director_address_file_list"
                :address_prove_file_list="
                    item.entity_director_address_file_list
                "
                @handleChange="(variable, value) => {handleChange(item.key, variable, value)}"
                @FileValidate="(filename)=> {validateField(filename, item.key)}"
              >
              </BaseAddressVertify>
            </div>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import BaseCountrySelection from '@/components/BaseCountrySelection'
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseId from '@/components/BaseId.vue'
import BaseAddressVertify from '@/components/BaseAddressVertify.vue'
import rules from '@/assets/rules/entityThirdRules.js'
import cnFilter from '@/utils/chineseFilter.js'
import BaseAddress from '@/components/BaseAddress.vue'

export default {
  data () {
    return {
      activeKey: 1,
      proveChannel: '',
      proveMethod: '',
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },

  created () {
    this.rules = rules
  },
  components: {
    BaseId,
    BaseAddressVertify,
    BaseCountrySelection,
    BaseAddress
  },
  computed: {
    paneData () {
      return this.$store.state.entityStepThree.director_panes[0]
    },
    ...mapState('countrySelection', ['popularCountryList']),
    ...mapGetters('layout', ['GetScreenStyle']),
    ...mapGetters('entityStepThree', ['getPanesLength']),
    ...mapState('entityStepThree', ['director_panes']),
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.disabled) {
      next()
    } else {
      Object.values(this.$refs).forEach(item => item[0].validate(valid => {
        if (valid) {
          next()
        } else {
          next()
        }
      }))
    }
  },
  methods: {
    validateField (file, key) {
      this.$refs['ShareHolder' + key][0].validateField(file)
    },
    onEdit (targetKey, action) {
      if (!this.disabled) {
        this[action](targetKey)
      }
    },
    add () {
      const panes = this.director_panes
      const nextKey = this.getPanesLength + 1
      panes.push({
        title: `Person Detail 成员信息 ${nextKey}`,
        key: nextKey,
        entity_director_postion: null,
        entity_director_title: null,
        entity_director_first_name: null,
        entity_director_last_name: null,
        entity_director_gender: null,
        entity_director_dob: null,
        entity_director_nationality: null,
        entity_director_place_of_birth: null,
        entity_director_tax_country: null,
        entity_director_tax_num: null,
        entity_director_address: null,
        entity_director_country: null,
        entity_director_city: null,
        entity_director_suburb: null,
        entity_director_postcode: null,
        entity_director_dialcode: null,
        entity_director_phonenum: null,
        entity_director_email: null,
        entity_director_identification_type: null,
        entity_director_id_num: null,
        entity_director_issue_date: null,
        entity_director_expire_date: null,
        entity_director_issue_country: null,

        entity_director_id_proveChannel: null,
        entity_director_id_proveMethod: null,

        entity_director_nz_au_passport_num: null,
        entity_director_nz_au_passport_country: null,
        entity_director_nz_au_passport_file: null,
        entity_director_nz_au_driver_num: null,
        entity_director_nz_au_driver_country: null,
        entity_director_nz_au_driver_front_file: null,
        entity_director_nz_au_driver_back_file: null,
        entity_director_cn_passport_num: null,
        entity_director_cn_passport_country: 'CN',
        entity_director_cn_passport_file: null,
        entity_director_cn_passport_visa_file: null,
        entity_director_cn_identify_num: null,
        entity_director_cn_identify_country: 'CN',
        entity_director_cn_identify_front_file: null,
        entity_director_cn_identify_back_file: null,
        entity_director_cn_bank_num: null,
        entity_director_cn_bank_country: 'CN',
        entity_director_cn_bank_file: null,
        entity_director_cn_passport_option_num: null,
        entity_director_cn_passport_option_country: 'CN',
        entity_director_cn_passport_option_file: null,
        entity_director_cn_passport_visa_option_file: null,
        entity_director_other_passport_option_num: null,
        entity_director_other_passport_option_country: null,
        entity_director_other_passport_option_file: null,
        entity_director_other_passport_visa_option_file: null
      })
      this.handleChangePane(panes)
      this.activeKey = nextKey
    },
    remove (targetKey) {
      let activeKey = this.activeKey
      let lastIndex
      this.director_panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      const panes = this.director_panes.filter(pane => pane.key !== targetKey)
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key
        } else {
          activeKey = panes[0].key
        }
      }
      this.handleChangePane(panes)
      this.activeKey = activeKey
    },
    handleChange (key, variable, value) {
      const index = this.director_panes.findIndex(item => item.key === key)
      this.handleChangeAction({ index, variable, value: cnFilter(value) })
    },
    ...mapActions('entityStepThree', ['handleChangeAction', 'handleChangePane'])
  }
}
</script>

<style scoped>
.modify_antd_radio >>> .ant-radio-inner {
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-inner::after {
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
</style>
